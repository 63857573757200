import React from "react"
import PropTypes from "prop-types"

import Layout from "../components/layout"

const NotFoundPage = ({ pageContext }) => {
  const { globalData } = pageContext;

  return (
    <Layout { ...globalData } >
      <section className="my-16 py-16 lg:py-32" >
        <h1 className="text-5xl text-center text-bold" >404</h1>
      </section>
    </Layout>
  )
}

NotFoundPage.propTypes = {
  pageContext: PropTypes.object.isRequired
}

export default NotFoundPage